import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
// @material-ui/icons
import Menu from '@material-ui/icons/Menu';
// core components
import AdminNavbarLinks from './AdminNavbarLinks.js';
import RTLNavbarLinks from './RTLNavbarLinks.js';
import Button from '../../components/CustomButtons/Button.js';

//hooks
import { useRouteName } from '../../hooks';

import styles from '../../assets/jss/material-dashboard-react/components/headerStyle.js';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(styles);

export default function Header(props) {
  const classes = useStyles();
  const routeName = useRouteName();
  const { color } = props;
  const appBarClasses = classNames({
    [' ' + classes[color]]: color,
  });
  return (
    // <AppBar className={classes.appBar + appBarClasses + ' page-header'}>
    <Box className="container transition-all mt-[100px] relative">
      {/* <h1 className='text-5xl font-extrabold'>{routeName}</h1> */}
      {/* <Toolbar className={classes.container}>
        <div className={classes.flex}>
           <Button color="transparent" href="#" className={classes.title + ' title-page'}>
            {routeName}
          </Button>
        </div>
        <Hidden smDown implementation="css">
          {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />}
        </Hidden>
        <Hidden mdUp implementation="css">
          <IconButton color="inherit" aria-label="open drawer" onClick={props.handleDrawerToggle}>
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar> */}
    </Box>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object),
};
