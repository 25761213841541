export const PresaleManageABI = 
[
	{
		"inputs": [
			{
				"internalType": "address payable",
				"name": "_owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "lock_addr",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "pancakefactory_addr",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "pancakerouter_Addr",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "WBNB_addr",
				"type": "address"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "CreatePreslaeSuccess",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "OwnerWithdrawSuccess",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "presale_addr",
				"type": "address"
			}
		],
		"name": "IsRegistered",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_amount",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_tokenPrice",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_listingRate",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_liquidityPBEPent",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_tokenFee",
				"type": "uint256"
			}
		],
		"name": "calculateAmountRequired",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "pure",
		"type": "function"
	},
	{
      "inputs": [
        {
          "components": [
            {
              "internalType": "address payable",
              "name": "presale_owner",
              "type": "address"
            },
            {
              "internalType": "address",
              "name": "sale_token",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "token_rate",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "raise_min",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "raise_max",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "hardcap",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "softcap",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "liqudity_percent",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "listing_rate",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "lock_end",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "lock_start",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "presale_end",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "presale_start",
              "type": "uint256"
            }
          ],
          "internalType": "struct SharedStructs.PresaleInfo",
          "name": "_presale_info",
          "type": "tuple"
        },
        {
          "components": [
            {
              "internalType": "string",
              "name": "website_link",
              "type": "string"
            },
            {
              "internalType": "string",
              "name": "github_link",
              "type": "string"
            },
            {
              "internalType": "string",
              "name": "twitter_link",
              "type": "string"
            },
            {
              "internalType": "string",
              "name": "reddit_link",
              "type": "string"
            },
            {
              "internalType": "string",
              "name": "telegram_link",
              "type": "string"
            }
          ],
          "internalType": "struct SharedStructs.PresaleLink",
          "name": "_presalelink",
          "type": "tuple"
        }
      ],
		"name": "createPresale",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getBalance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "getPresaleAt",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "ownerWithdraw",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "presale_lock_forward_addr",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "presale_setting_addr",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "settings",
		"outputs": [
			{
				"internalType": "contract IPresaleSettings",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
]