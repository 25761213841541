//Default
import React from 'react';

//Group
import GridItem from '../../components/Grid/GridItem.js';
import GridContainer from '../../components/Grid/GridContainer.js';

function Home() {
  return (
    <>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={8} justifyContent="center" class="dashboard-content">
          <h3>Welcome to <a href='https://zedscan.net' className='text-[#1FC7D4]'>zedscan.net</a> a brand new DApp on the <a href="http://zedscan.net" target='_blank' className='text-[#1FC7D4]'>zedscan.net</a> blockchain</h3>
          <h1>Secure DeFi Infrastructure </h1>
          <p class="p-fist">
            The Launch of <a href='https://zedscan.net' className='text-[#1FC7D4]'>zedscan.net</a> allows everybody to create their own tokens and token sales in a few taps and clicks. Tokens created on <a href='https://zedscan.net' className='text-[#1FC7D4]'>zedscan.net</a> will automatically be verified and viewable on the <a href='http://zedscan.net' className='text-[#1FC7D4]' target='_blank'>zedscan.net</a> explorer. 
          </p>
          <p>Join us on our journey to the gold standard of crypto</p>
        </GridItem>
      </GridContainer>
    </>
  );
}

export default Home;
