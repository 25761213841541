//Default
import React from 'react';

//Group
import GridItem from '../../components/Grid/GridItem.js';
import GridContainer from '../../components/Grid/GridContainer.js';

import Card from '../../components/Card/Card.js';
import CardBody from '../../components/Card/CardBody.js';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const CustomTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: 'transparent',
    color: '#868DA6',
    fontSize: 18,
  },
  body: {
    fontSize: 18,
  },
}))(TableCell);

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 600,
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
});

function createData(name, address, link) {
  return { name, address, link };
}

const rows = [
  createData(
    'Token Factory CreateManage: ',
    '0xa64b7fad0131b1b386ddfef938685b95a31c6ca9',

    <a target={'_blank'} href="https://gchainexplorer.genesys.network/address/0xa64b7fAD0131B1b386DDFEF938685B95a31c6CA9">
      {' '}
      Explore
    </a>
  ),
  createData(
    'Token Lock: ',
    '0x709cc16ed956c9ff486ecc4354efce3968ab2893',
    <a target={'_blank'} href="https://gchainexplorer.genesys.network/address/0x709cc16ED956C9fF486ECc4354eFCe3968Ab2893">
      {' '}
      Explore
    </a>
  ),
  createData(
    'Liquidity Lock: ',
    '0xf341d72be803f87c45976f4cd0defa3ec6b2e9b1',
    <a target={'_blank'} href="https://gchainexplorer.genesys.network/address/0xF341D72BE803f87C45976f4cd0deFA3Ec6b2E9B1">
      {' '}
      Explore
    </a>
  ),
  createData(
    'Presale Manage: ',
    '0x13a17fa7acee8e95463746a74ecf02d9d97634b7',
    <a target={'_blank'} href="https://gchainexplorer.genesys.network/address/0x13a17fA7aceE8e95463746a74eCF02D9d97634b7">
      {' '}
      Explore
    </a>
  ),
  createData(
    'Lending PoolFactory: ',
    '0xd669d5bfb4edcb430525d8cea7075236432c69ea',
    <a target={'_blank'} href="https://gchainexplorer.genesys.network/address/0xD669D5Bfb4EdCB430525D8cEa7075236432C69eA">
      {' '}
      Explore
    </a>
  ),
  createData(
    'Staking PoolFactory: ',
    '0x22e33308d265d1bba7efa2d6b5a5bbda7391525c',
    <a target={'_blank'} href="https://gchainexplorer.genesys.network/address/0x22E33308d265d1bBa7eFA2D6b5A5BBda7391525c">
      {' '}
      Explore
    </a>
  ),
  createData(
    'MSwap Factory:  ',
    '0x773da64819850fb7a0a946ad081b6e9f73b78ce4',
    <a target={'_blank'} href="https://gchainexplorer.genesys.network/address/0x773dA64819850FB7A0a946AD081b6e9F73B78Ce4">
      {' '}
      Explore
    </a>
  ),
];

function Contracts(props) {
  const { classes } = props;

  return (
    <>
      {/* <GridContainer justifyContent="center">
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardBody></CardBody>
          </Card>
        </GridItem>
      </GridContainer> */}

      <TableContainer component={Paper} sx={{backgroundColor: 'transparent'}}>
        <Table sx={{ borderSpacing: '0 5px', borderCollapse: 'initial' }} aria-label="simple table">
          <TableHead>
            <TableRow 
              sx={{ 
                '& th': {border: 'none', color: '#868DA6', paddingBottom: '10px', fontSize: '14px'}, 
                '& th:first-child': {paddingLeft: '26px'},
                '& th:last-child': {paddingRight: '26px'}
              }}
            >
              <TableCell>CONTRACT NAME</TableCell>
              <TableCell>ADDRESS</TableCell>
              <TableCell>EXPLORE</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { rows.map((row, index) => (
              <TableRow
                sx={{ 
                  '& td': {border: 'none', background: '#181818', color: '#ffffff', paddingTop: '18px', paddingBottom: '18px', fontSize: '16px'}, 
                  '& td:first-child': {borderTopLeftRadius: '6px', borderBottomLeftRadius: '6px', paddingLeft: '26px'},
                  '& td:last-child': {borderTopRightRadius: '6px', borderBottomRightRadius: '6px', paddingRight: '26px'}
                }}
                key={index}
              >
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.address}</TableCell>
                <TableCell>{row.link}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <CustomTableCell>Contract Name</CustomTableCell>
              <CustomTableCell align="Left">
                <b>Address</b>
              </CustomTableCell>
              <CustomTableCell align="Left">Explore</CustomTableCell>
              <CustomTableCell align="right">Fat (g)</CustomTableCell>
              <CustomTableCell align="right">Carbs (g)</CustomTableCell>
              <CustomTableCell align="right">Protein (g)</CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow className={classes.row} key={row.id}>
                <CustomTableCell width={300} component="th" scope="row">
                  <b> {row.name}</b>
                </CustomTableCell>
                <CustomTableCell width={600} align="left">
                  <b>{row.address}</b>
                </CustomTableCell>
                <CustomTableCell align="left">
                  <b style={{ color: 'pink' }}>{row.link}</b>
                </CustomTableCell>
                <CustomTableCell align="right">{row.fat}</CustomTableCell>
                <CustomTableCell align="right">{row.carbs}</CustomTableCell>
                <CustomTableCell align="right">{row.protein}</CustomTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper> */}
    </>
  );
}

Contracts.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Contracts);
