/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
// core components
import styles from "../../assets/jss/material-dashboard-react/components/footerStyle.js";
import { Box } from "@mui/material";
import LogoFooter from "../../assets/img/logo_footer.png"
import LogoZedScan from "../../assets/img/logoZedScan.png"
import LogoZedDex from "../../assets/img/logoZedDex.png"
import LogoZedStake from "../../assets/img/logoZedStake.png"
import LogoZedToken from "../../assets/img/logoZedToken.png"
import LogoZedDrop from "../../assets/img/logoZedDrop.png"
import LogoChakra from "../../assets/img/chakra.png"
import LogoChakra1 from "../../assets/img/chakra1.png"
import LogoChakra2 from "../../assets/img/chakra2.png"
import LogoChakra3 from "../../assets/img/chakra3.png"
import LogoChakra4 from "../../assets/img/chakra4.png"
import LogoChakra5 from "../../assets/img/chakra5.png"
import LogoChakra6 from "../../assets/img/chakra6.png"
import LogoChakra7 from "../../assets/img/chakra7.png"


const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  return (
    <footer className="w-full bg-[#ffffff]">
      <Box className="w-full">
        <Box className="container flex flex-wrap min-h-[240px] py-[40px]">
          <Box className="flex flex-col items-center col-span-6 col-md-6 footer-left" >
            <Box className="mr-auto flex">
              <img src={LogoFooter} /> <p style={{color:'#313743',fontWeight: '700',fontSize: '17px',paddingTop: '15px',paddingLeft: '15px'}}>Power by ZEDXION Smart Chain</p>
            </Box>
            <Box className="mr-auto flex mt-[30px]" style={{color:'#313743'}}>
            ZedToken enables the creation of decentralized applications,token building, fostering innovation, efficiency, and transparency in economic ecosystems.
            </Box>
            <Box className="mr-auto flex flex-wrap gap-5 mt-[30px] footer-link-1" style={{color:'#313743'}}>
              <a href="https://zed-pay.com/" target="_blank" className="flex flex-wrap gap-2">
                <img src={LogoZedScan} style={{ width:'17px',height:'18px' }} /> 
                NeoBank(ZedPay)
              </a>
              <a href="https://www.zedxion.io/en_US/" target="_blank" className="flex flex-wrap gap-2">
                <img src={LogoZedDex} style={{ width:'21px',height:'17px',marginTop: '2px' }} /> 
                CEX1(ZEDXION)
              </a>
              <a href="https://www.zedcex.com/en_US/market" target="_blank" className="flex flex-wrap gap-2">
                <img src={LogoZedStake} style={{ width:'17px',height:'17px', marginTop: '2px' }} /> 
                CEX2(ZedCex)
              </a>
              <a href="https://zedscan.net" target="_blank" className="flex flex-wrap gap-2">
                <img src={LogoZedScan} style={{ width:'17px',height:'18px' }} /> 
                ZedScan
              </a>
            </Box>
            <Box className="mr-auto flex flex-wrap gap-5 mt-[30px] footer-link-1" style={{color:'#313743'}}>              
              <a href="https://zeddex.com" target="_blank" className="flex flex-wrap gap-2">
                <img src={LogoZedDex} style={{ width:'21px',height:'17px',marginTop: '2px' }} /> 
                ZedDex
              </a>
              <a href="http://stake.zedxion.com" target="_blank" className="flex flex-wrap gap-2">
                <img src={LogoZedStake} style={{ width:'17px',height:'17px', marginTop: '2px' }} /> 
                ZedStake
              </a>
              <a href="http://bridge.zedxion.com" target="_blank" className="flex flex-wrap gap-2">
                <img src={LogoZedToken} style={{ width:'21px',height:'15px',marginTop: '4px' }} /> 
                ZedBridge
              </a>
              <a href="https://airdrop.zedxion.com" target="_blank" className="flex flex-wrap gap-2">
                <img src={LogoZedDrop} style={{ width:'21px',height:'15px',marginTop: '4px' }} /> 
                ZedDrop
              </a>
            </Box>
            <Box className="mr-auto flex flex-wrap gap-5 mt-[30px]" style={{color:'#313743'}}>
              <a href="https://www.facebook.com/zedxioncoin" target="_blank">
                <img src={LogoChakra} /> 
              </a>
              <a href="https://twitter.com/zedxionc" target="_blank">
                <img src={LogoChakra1} /> 
              </a>
              <a href="https://www.instagram.com/zedxion/" target="_blank">
                <img src={LogoChakra2} /> 
              </a>
              <a href="https://www.youtube.com/channel/UCVbVWWdHHrpRkRL80_CoDZg" target="_blank">
                <img src={LogoChakra3} /> 
              </a>
              <a href="https://discord.com/invite/UDDb7FZF" target="_blank">
                <img src={LogoChakra4} /> 
              </a>
              <a href="https://medium.com/subscribe/@zedxion_exchange" target="_blank" style={{ marginTop: '4px' }}>
                <img src={LogoChakra5} /> 
              </a>
              <a href="https://www.linkedin.com/company/zedxion-exchnage-crypto" target="_blank">
                <img src={LogoChakra6} /> 
              </a>
              <a href="https://github.com/Zedxioncryptocurrency" target="_blank">
                <img src={LogoChakra7} /> 
              </a>
            </Box>
            <Box className="mr-auto text-[17px] flex flex-wrap mt-[50px] mb-[20px] footer-right" style={{color:'#313743'}}>
              ZEDXION Chain © 2024      |    
              <a href="https://docs.zedscan.net/zedxion-whitepaper/" target="_blank" className="ml-[5px]" style={{color:'#1FC7D4'}}>
                WhitePaper
              </a>
            </Box>
          </Box>
          <Box className="flex gap-[25px] flex-wrap pl-[10px] col-md-6 col-span-5">
            <Box className="flex gap-[25px] flex-col pr-[50px] md:col-span-5">
              <a  style={{color:'#313743',fontWeight:'600'}} className="text-[16px]">
                Company
              </a>
              <a  style={{color:'#313743',fontWeight:'500'}} className="text-[13px]">
                Delegate to ZedScan
              </a>
              <a  style={{color:'#313743'}} className="text-[13px]">
                <p style={{background: '#0CC1E0',borderRadius:'13px',padding:'4px 8px',width: '70px', fontSize:'13px', textAlign:'center'}}>Staking</p>
              </a>
              <a  style={{color:'#313743'}} className="text-[13px]">
                Brand Assets
              </a>
              <a  style={{color:'#313743'}} className="text-[13px]">
                Contact Us
              </a>
              <a  style={{color:'#313743'}} className="text-[13px]">
                Terms & Privacy
              </a>
              <a  style={{color:'#313743'}} className="text-[13px]">
                Bug Bounty
              </a>
            </Box>
            <Box className="flex gap-[25px] flex-col pr-[50px] md:col-span-5">
              <a  style={{color:'#313743',fontWeight:'600'}} className="text-[16px]">
                Community
              </a>
              <a  style={{color:'#313743'}} className="text-[13px]">
                API Documentation
              </a>
              <a  style={{color:'#313743'}} className="text-[13px]">
                Knowledge Base
              </a>
              <a  style={{color:'#313743'}} className="text-[13px]">
                Network Status
              </a>
              <a  style={{color:'#313743'}} className="text-[13px]">
                Learn ZEDSCAN
              </a>
            </Box>
            <Box className="flex gap-[25px] flex-col md:col-span-5">
              <a style={{color:'#313743',fontWeight:'600'}} className="text-[16px]">
                Products & Services
              </a>
              <a style={{color:'#313743'}} className="text-[13px]">
                Advertise
              </a>
              <a style={{color:'#313743'}} className="text-[13px]">
                Explorer-as-a-Service (EaaS)
              </a>
              <a style={{color:'#313743'}} className="text-[13px]">
                API Plans
              </a>
              <a style={{color:'#313743'}} className="text-[13px]">
                Priority Support
              </a>
              <a style={{color:'#313743'}} className="text-[13px]">
                Blockscan
              </a>
            </Box>
          </Box>
        </Box>
      </Box>
    </footer>
  );
}
