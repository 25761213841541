//Default
import React from 'react';
import { useState, useEffect } from 'react';

//Style
import { makeStyles } from '@material-ui/core/styles';
import Loader from '../Loader/Loader.js';
import CircularProgress from '@mui/material/CircularProgress';

// import Chip from '@mui/material/Chip';

//Group
import GridItem from '../Grid/GridItem.js';
import GridContainer from '../Grid/GridContainer.js';

import Card from '../Card/Card.js';
import CardHeader from '../Card/CardHeader.js';
import CardBody from '../Card/CardBody.js';
import CardAvatar from '../Card/CardAvatar.js';
import CardFooter from '../Card/CardFooter.js';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

//Input
import TextField from '@mui/material/TextField';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { Label, Input } from 'reactstrap';
import Radio from '@mui/material/Radio';
// import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Search from '@material-ui/icons/Search';
import Link from '@mui/material/Link';

//Component
import CustomInput from '../CustomInput/CustomInput.js';
import Button from '@mui/material/Button';

// import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Table from '../Table/Table.js';
import CircularProgressWithLabel from '../Progress/CircleProgress.js';

//Color
import Danger from '../Typography/Danger.js';
import Success from '../Typography/Success.js';
import Primary from '../Typography/Primary.js';
import Info from '../Typography/Info.js';

//Utility
import { ethers, BigNumber as EthersBigNumber } from 'ethers';

import { red, pink, purple, deepPurple, indigo, blue, green, lightGreen, deepOrange } from '@mui/material/colors';

//Icon
import ErrorIcon from '@material-ui/icons/Error';
import GetAppIcon from '@mui/icons-material/GetApp';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import CardIcon from '../../components/Card/CardIcon.js';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'PP Telegraf', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};

const useStyles = makeStyles(styles);

export default function FeeInfo(props) {
  const classes = useStyles();

  const { fee, cur_coin, getFeeInfo } = props;

  const getFeeButtonClick = () => {
    getFeeInfo();
  };

  return (
    <Box className="">
      <h4 className="text-[17px] font-extrabold" style={{color:'#334155'}}>Withdraw {cur_coin}</h4>
      <Box className="flex justify-end mt-3 mb-8 border-t-[#E2E8EE]" >
        <button className="flex gap-[27px]" style={{color:'#334155'}} onClick={(e) => getFeeButtonClick()}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none">
            <path d="M13 0H7V9H3L10 16L17 9H13V0Z" fill="#334155" />
            <rect y="20" width="20" height="2" fill="#334155" />
          </svg>
          Get Fee
        </button>
      </Box>
      <Box className="border-t border-t-[#E2E8EE] py-5 flex gap-5 items-center justify-between">
        <p className="text-[17px] font-extrabold" style={{color:'#334155'}}>Normal Fee:</p>
        <p className="text-[20px] font-extrabold" style={{color:'#334155'}}>{fee.normal}</p>
      </Box>
      <Box className="border-t border-t-[#E2E8EE] py-5 flex gap-5 items-center justify-between">
        <p className="text-[17px] font-extrabold" style={{color:'#334155'}}>Mint Fee:</p>
        <p className="text-[20px] font-extrabold " style={{color:'#334155'}}>{fee.mint}</p>
      </Box>
      <Box className="border-t border-t-[#E2E8EE] py-5 flex gap-5 items-center justify-between">
        <p className="text-[17px] font-extrabold" style={{color:'#334155'}}>Burn Fee:</p>
        <p className="text-[20px] font-extrabold " style={{color:'#334155'}}>{fee.burn}</p>
      </Box>
      <Box className="border-t border-t-[#E2E8EE] py-5 flex gap-5 items-center justify-between">
        <p className="text-[17px] font-extrabold" style={{color:'#334155'}}>Pause Fee:</p>
        <p className="text-[20px] font-extrabold " style={{color:'#334155'}}>{fee.pause}</p>
      </Box>
      <Box className="border-t border-t-[#E2E8EE] py-5 flex gap-5 items-center justify-between">
        <p className="text-[17px] font-extrabold" style={{color:'#334155'}}>Blacklist Fee:</p>
        <p className="text-[20px] font-extrabold " style={{color:'#334155'}}>{fee.blacklist}</p>
      </Box>
      <Box className="border-t border-t-[#E2E8EE] py-5 flex gap-5 items-center justify-between">
        <p className="text-[17px] font-extrabold" style={{color:'#334155'}}>Tax Fee:</p>
        <p className="text-[20px] font-extrabold " style={{color:'#334155'}}>{fee.deflation}</p>
      </Box>
      <Box className="border-t border-t-[#E2E8EE] py-5 flex gap-5 items-center justify-between">
        <p className="text-[17px] font-extrabold" style={{color:'#334155'}}>Current Fee:</p>
        <p className="text-[20px] font-extrabold " style={{color:'#334155'}}>{fee.curfee}</p>
      </Box>
    </Box>
  );
}
